

function BannerProjects({ title }) {

    return (
        <div className="banner-projects">
            <h1>{title}</h1>
        </div>
    )
}

export default BannerProjects;