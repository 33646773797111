
function BannerHome() {
    return (
        <div className="banner-home">
            <h1>Portfolio</h1>
            <h2>Eryan Gauvrit</h2>
        </div>
    )
}

export default BannerHome;